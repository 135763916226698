<template>
  <div id="development-section">
    <LetsChat></LetsChat>
    <section class="code-hac">
      <div class="romb romb1 mouse-move group1" style="top: 332.754px; left: 54.2961px;"></div>
      <div class="romb romb2 mouse-move group3" style="top: 313.983px; left: 252.815px;"></div>
      <div class="romb romb3 mouse-move group2" style="top: 391.997px; left: 482.163px;"></div>
      <div class="romb romb4 mouse-move group3" style="top: 247.984px; left: 791.815px;"></div>
      <div class="romb romb5 mouse-move group2" style="top: 259.997px; left: 886.163px;"></div>
      <div class="romb romb6 mouse-move group1" style="top: 266.754px; left: 1133.3px;"></div>
      <div class="romb romb7 mouse-move group2" style="top: 589.997px; left: 1021.16px;"></div>
      <div class="romb romb8 mouse-move group3" style="top: 610.984px; left: 1061.82px;"></div>

      <div class="container-code-hac">
        <h3>Scriptics Developers</h3>
        <button id="toggle-hac">Code</button>
        <div class="left-hac">
            <pre class="pre-css" style="display: none;">
                #lines {
                    display: block;
                    margin-left: -303px;
                    top: 1px;
                    left: 50%;
                }
                #pattern {
                    display: block;
                    top: 25%;
                    left: 25%;
                }
                #diamond {
                    display: block;
                    border: 1px solid #506273;
                    border-radius: 10px;
                    transform: rotate(45deg);
                    left: 50%;
                    top: 50%;
                    margin-left: -194px;
                    margin-top: -194px;
                }
                #elipses {
                    display: block;
                    top: 50%;
                    left: 50%;
                    margin-left: -232px;
                    margin-top: -239px;
                }
            </pre>
          <span class="indicate"></span>
        </div>

        <div class="right-hac">
          <div id="lines"></div>
          <div id="pattern"></div>
          <div id="diamond"></div>
          <div id="elipses"></div>
        </div>

        <a href="#" id="re-run-btn">Re-run</a>
      </div>

      <div class="we-dev">
        <div class="left">
          <h4>We Develop</h4>
          <p>Our team of system administrators and developers will help you create and maintain a stable cloud environment with an architecture that meets the highest standards; monitoring your servers and solving issues before they become a problem.</p>
        </div>
        <div class="right">
          <h6>Custom Web Apps</h6>
          <h6>Custom CMS</h6>
          <h6>Maintenance</h6>
          <h6>Consultancy</h6>
          <h6>SEO</h6>
          <h6>Hosting</h6>
        </div>
      </div>
    </section>

<!--    <section class="map-hac">-->
<!--      <img :src="mapDevelopment"/>-->
<!--    </section>-->
    <ContactUs></ContactUs>
    <Footer color="#181818"></Footer>
  </div>
</template>


<script>
import $ from 'jquery'
import mapdev from '@/assets/dev/map-development.svg';
import ContactUs from "../components/ContactUs";
import Footer from "../components/Home/Footer";
import LetsChat from "../components/LetsChat";


export default {
  name: "Development",
  components: {LetsChat, Footer, ContactUs},
  data() {
    return {
      mapDevelopment: mapdev,
    }
  },
  mounted() {
    $(document).ready(function () {

      var rate = 30;
      var timerId;
      var content = $('.pre-css').html();
      $('.container-code-hac').prepend('<style></style>');

      function typeHAC(content, name, index) {
        var cssID = false;
        var cssValue = false;
        $('.pre-css').html("");
        $('.container-code-hac style').html("");
        $('.pre-css').show();
        timerId = setInterval(function () {
          $('.container-code-hac style').append(content[index]);
          if ((cssValue == false) && (content[index + 1] == '#')) {
            cssID = true;
          } else if (content[index + 1] == '{') {
            cssID = false;
          }
          if ((cssID == false) && (content[index] == ':')) {
            cssValue = true;
          } else if (content[index + 1] == ';') {
            cssValue = false;
          }
          if (cssID == true) {
            $(name).append('<span class="css-id">' + content[(++index)] + '</span>');
          } else if (cssValue == true) {
            $(name).append('<span class="css-value">' + content[(++index)] + '</span>');
          } else {
            $(name).append(content[++index]);
          }
        }, rate);
      }

      $("#re-run-btn").on('click', function () {
        clearInterval(timerId);
        typeHAC(content, '.pre-css', -1);
      });
      typeHAC(content, '.pre-css', -1);

      $(".container-code-hac #toggle-hac").on('click', function () {
        if ($(".container-code-hac").hasClass("s-code")) {
          $(".container-code-hac").removeClass('s-code');
          $(".container-code-hac #toggle-hac").text('Code');
        } else {
          $(".container-code-hac").addClass('s-code');
          $(".container-code-hac #toggle-hac").text('Result');
        }
      });
    });
  },
}

</script>
<style>
#development-section {
  display: block;
}

.code-hac {
  position: relative;
  background: #1f1f1f;
  min-height: 800px;
  border: none;
}

.romb {
  transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  position: absolute;
  border-radius: 10px;
}

.romb1 {
  width: 13.5em;
  height: 13.5em;
  background-color: rgba(255, 255, 255, .1);
  top: 50%;
  left: 5%
}

.romb2 {
  width: 8.625em;
  height: 8.625em;
  background-color: rgba(255, 255, 255, .3);
  top: 50%;
  left: 15%
}

.romb3, .romb4 {
  border: 1px solid rgba(255, 255, 255, .5)
}

.romb6, .romb7 {
  background-color: rgba(255, 255, 255, .2)
}

.romb3 {
  width: 5.75em;
  height: 5.75em;
  top: 60%;
  left: 35%
}

.romb4 {
  width: 18em;
  height: 18em;
  top: 40%;
  left: 50%
}

.romb5 {
  width: 10em;
  height: 10em;
  border: 1px solid rgba(255, 255, 255, .1);
  left: 65%;
  top: 40%
}

.romb6 {
  width: 10.625em;
  height: 10.625em;
  left: 85%;
  top: 40%
}

.romb7 {
  width: 12.75em;
  height: 12.75em;
  left: 75%;
  top: 90%
}

.romb8 {
  width: 18.75em;
  height: 18.75em;
  background-color: rgba(255, 255, 255, .3);
  left: 75%;
  top: 95%
}

.code-hac:after, .code-hac:before {
  height: 649px;
  left: 50%;
  margin-left: -304px;
  position: absolute;
  width: 607px;
  background: url(../assets/dev/lines.png);
  display: block;
  content: "";
}

.code-hac:before {
  top: 130px;
}

.code-hac:after {
  bottom: 130px;
}

.code-hac .romb {
  opacity: .08;
}

.container-code-hac {
  position: relative;
  z-index: 99;
  display: block;
  margin: 0 auto;
  padding: 134px 0 0;
  width: 100%;
  max-width: 1200px
}

.container-code-hac h3 {
  width: 100%;
  display: block;
  clear: both;
  text-align: center;
  background: #2d98c8;
  height: 30px;
  line-height: 30px;
  color: #fff;
  font-size: 16px;
  font-weight: lighter;
  -moz-border-radius-topleft: 4px;
  -webkit-border-top-left-radius: 4px;
  border-top-left-radius: 4px;
  -moz-border-radius-topright: 4px;
  -webkit-border-top-right-radius: 4px;
  border-top-right-radius: 4px;
}

.container-code-hac .left-hac, .container-code-hac .right-hac {
  display: block;
  height: 655px;
  border-bottom: 1px solid #2d98c8;
  margin-bottom: 50px;
  width: 50%;
  float: left;
}

.container-code-hac .left-hac {
  border-left: 1px solid #2d98c8;
  background: #07192b;
  padding: 20px 0;
}

.container-code-hac .right-hac {
  border-right: 1px solid #2d98c8;
  background: #132c46;
  position: relative;
  overflow: hidden
}

#diamond, #elipses, #lines, #pattern {
  display: none;
  position: absolute
}

#lines {
  width: 607px;
  height: 649px;
  background: url(../assets/dev/lines.png)
}

#pattern {
  width: 50%;
  height: 50%;
  background: url(../assets/dev/pattern-scriptics-diamond.png)
}

#diamond {
  height: 388px;
  width: 388px
}

#elipses {
  width: 465px;
  height: 478px;
  background: url(../assets/dev/s-elipses.png)
}

.pre-css {
  color: #ccc;
  display: block;
  font-family: Lato-Light, sans-serif;
  font-size: 16px;
  height: 100%;
  width: 100%
}

.css-id {
  color: #20c99f
}

.css-value {
  color: #df863d
}

.indicate {
  font-family: Lato-Light, sans-serif;
  font-size: 16px;
  display: inline-block
}

#re-run-btn,
.code-hac .we-dev .left h4,
.code-hac .we-dev .left p {
  font-family: Lato-Light, sans-serif
}

#re-run-btn {
  display: block;
  width: 100%;
  margin: 0 auto;
  clear: both;
  text-align: left;
  padding: 0 5px 0 15px;
  height: 45px;
  line-height: 43px;
  border: 1px solid #2d98c8;
  -moz-border-radius: 30px;
  -webkit-border-radius: 30px;
  border-radius: 30px;
  max-width: 300px;
  font-size: 20px;
  color: #00aeef;
  background: url(../assets/dev/rerun.svg) right center no-repeat;
  background-size: 35px;
  background-origin: content-box;
  -webkit-transition: all .5s ease;
  -moz-transition: all .5s ease;
  -ms-transition: all .5s ease;
  -o-transition: all .5s ease;
  transition: all .5s ease;
  text-decoration: none;
}

#re-run-btn:hover {
  background: url(../assets/dev/rerun-hover.svg) right center no-repeat #00aeef;
  background-size: 35px;
  background-origin: content-box;
  color: #fff;
}

#re-run-btn:after {
  clear: both;
  content: "";
  display: block;
  height: 0;
  line-height: 0;
  visibility: hidden
}

.code-hac .we-dev {
  display: block;
  max-width: 1200px;
  position: relative;
  z-index: 99;
  margin: 0 auto;
  padding: 14em 0 37em;
  width: 100%
}

.code-hac .we-dev .left {
  display: block;
  float: left;
  background: #282828;
  margin: 0;
  width: 50%;
  padding: 52px 38px 100px
}

.code-hac .we-dev .left h4 {
  display: block;
  text-align: center;
  color: #00aeef;
  font-size: 50px;
  padding-bottom: 38px
}

.code-hac .we-dev .left p {
  font-size: 18px;
  text-align: center;
  color: #ccc;
  line-height: 30px
}

.code-hac .we-dev .right {
  display: block;
  float: left;
  margin: 0;
  width: 50%;
  padding-left: 9%
}

.code-hac .we-dev .right h6 {
  padding-left: 60px;
  display: block;
  position: relative;
  font-size: 40px;
  color: #444;
  margin-top: 20px;
  -webkit-transition: all .5s ease;
  -moz-transition: all .5s ease;
  -ms-transition: all .5s ease;
  -o-transition: all .5s ease;
  transition: all .5s ease
}

.code-hac .we-dev .right h6:before {
  content: "";
  display: block;
  height: 3px;
  left: 0;
  position: absolute;
  bottom: 8px;
  width: 0;
  background: #00aeef;
  -webkit-transition: all .5s ease;
  -moz-transition: all .5s ease;
  -ms-transition: all .5s ease;
  -o-transition: all .5s ease;
  transition: all .5s ease
}

.code-hac .we-dev .right h6:hover {
  color: #00aeef
}

.code-hac .we-dev .right h6:hover::before {
  width: 50px
}

.container-code-hac #toggle-hac {
  display: none
}

.map-hac {
  padding: 86px 0;
  background: #07192b;
  border: none !important;

}

.map-hac .ctimg {
  display: none;
  margin: 0 auto;
  max-width: 1200px;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.map-hac .ctimg p {
  width: 100%;
  display: block;
  height: 40px;
  font-weight: lighter;
  text-align: center;
  background: rgba(0, 0, 0, .6);
  line-height: 40px;
  font-size: 16px;
  color: #474747;
  margin-top: 30px;
  position: relative
}

.map-hac .ctimg p:after,
.map-hac .ctimg p:before {
  display: block;
  background-size: contain;
  background-repeat: no-repeat;
  width: 34px;
  height: 22px;
  content: " ";
  font-size: 0;
  position: absolute;
  top: 9px
}

.map-hac .ctimg p:before {
  background-image: url(../assets/left-long-arrow-blue.svg);
  left: 10px
}

.map-hac .ctimg p:after {
  background-image: url(../assets/right-long-arrow-blue.svg);
  right: 10px
}

.map-hac > img {
  margin: 0 auto;
  display: block;
  position: relative;
  display: block;
  width: 100%;
  background: no-repeat;
  background-size: cover;
  border: none !important;

}

@media only screen and (max-width: 1127px) {
  .map-hac {
    padding: 40px 0 0;
  }

  .map-hac {
    display: block;
  }
}

@media only screen and (max-width: 940px) {
  .code-hac {
    padding-left: 8px;
    padding-right: 8px;
  }

  .container-code-hac {
    overflow: hidden;
  }

  .container-code-hac .left-hac {
    border-right: 1px solid #2d98c8;
    left: 100%;

    top: 164px;
    width: 100%;
    z-index: 1;
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -ms-transition: all .5s ease;
    -o-transition: all .5s ease;
    transition: all .5s ease
  }

  .container-code-hac.s-code .left-hac {
    left: 0
  }

  .container-code-hac .right-hac {
    width: 100%;
    border-left: 1px solid #2d98c8
  }

  .container-code-hac #toggle-hac {
    border: none;
    color: #d3d3d3;
    display: block;
    font-size: 14px;
    height: 28px;
    left: -19px;
    line-height: 28px;
    position: absolute;
    text-align: center;
    top: 187px;
    width: 70px;
    z-index: 3;
    background: rgba(0, 0, 0, .5);
    -moz-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    cursor: pointer;
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -ms-transition: all .5s ease;
    -o-transition: all .5s ease;
    transition: all .5s ease
  }

  .code-hac .we-dev .left, .code-hac .we-dev .right {
    clear: both;
    display: block;
    float: none;
    width: 100%
  }

  .container-code-hac #toggle-hac:hover {
    background: rgba(0, 0, 0, 1)
  }

  .container-code-hac.s-code #toggle-hac {
    left: auto;
    right: -19px
  }

  .code-hac .we-dev .left:after, .code-hac .we-dev .right:after {
    clear: both;
    content: "";
    display: block;
    height: 0;
    line-height: 0;
    visibility: hidden
  }

  .code-hac .we-dev .right {
    margin: 0 auto;
    max-width: 372px;
    padding: 0
  }
  .code-hac .we-dev {
    padding: 12vh 0;
  }
}
</style>